import { Injectable } from '@angular/core';

@Injectable()
export class ScriptService {
  appendScript(src): void {
    const scriptAvailable = document.querySelector(`script[src="${src}"]`);
    if (!scriptAvailable) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = src;
      document.body.appendChild(script);
    }
  }

  removeScript(src): void {
    const script = document.querySelector(`script[src="${src}"]`);
    if (script) {
      script.remove();
    }
  }
}
